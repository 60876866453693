import { cloudComputing } from "../../../assets";
import { ProgramIntroCard } from "../../cards";

function CloudComputingIntro() {
  return (
    <>
      <ProgramIntroCard
        title="Introduction to Cloud Computing Fundamental "
        details="Cloud Computing Fundamentals course offers a comprehensive introduction to the core concepts and technologies behind cloud computing. Whether you're a beginner or an IT professional looking to expand your skill set, this course provides a strong foundation in cloud services, infrastructure, and deployment models. You’ll learn about the benefits of cloud computing, the differences between public, private, and hybrid clouds, and how to leverage leading cloud platforms like AWS, Azure, and Google Cloud to build scalable, flexible, and secure applications."
        programImg={cloudComputing}
        downloadLink="#"
      />
    </>
  );
}

export default CloudComputingIntro;
