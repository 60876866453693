import { useEffect, useRef, useState } from "react";
import {
  advancedCc,
  cloudComputing,
  itpecProgram,
  softwareDevFound,
  softwareTesting,
  uiUx,
} from "../../assets";
import ProgrammeCard from "../cards/ProgrammeCard";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import { FaPause, FaPlay } from "react-icons/fa";

function ProgrammeCarousel() {
  const programmes = [
    {
      title: "ITPEC",
      info: "Master IT skills and ace the ITPEC exam with expert-led courses, hands-on practice, and industry-recognized certification.",
      image: itpecProgram,
      programInfo: "/itpec",
    },
    {
      title: "Software Development Foundations",
      info: "The essentials of software development. Learn coding, project management, agile practices, and more with hands-on training.",
      image: softwareDevFound,
      programInfo: "/software-development",
    },
    {
      title: "Cloud Computing Fundamental",
      info: "Master the basics of cloud computing and gain essential skills to leverage cloud technology for scalable, efficient solutions.",
      image: cloudComputing,
      programInfo: "/coming-soon",
    },
    {
      title: "Advanced Cloud Computing Bootcamp",
      info: "Master cloud infrastructure, deployment, and security in our Advanced Cloud Computing Bootcamp. Elevate your cloud expertise today!",
      image: advancedCc,
      programInfo: "/coming-soon",
    },
    {
      title: "Professional in UI/UX Design",
      info: "Master UI/UX design with expert-led classes. Learn user-centered design, wireframing, prototyping, and create stunning digital experiences.",
      image: uiUx,
      programInfo: "/coming-soon",
    },
    {
      title: "Professional in Software Testing",
      info: "Master the art of Software Testing. Gain in-demand skills, ensure quality, and accelerate your career in tech today!",
      image: softwareTesting,
      programInfo: "/coming-soon",
    },
  ];

  const containerRef = useRef(null);
  const scrollAmount = useRef(0); // Store scrollAmount in useRef
  const [isPaused, setIsPaused] = useState(false); // For pausing automatic scrolling

  useEffect(() => {
    if (isPaused) return;

    const scrollInterval = setInterval(() => {
      if (containerRef.current) {
        const scrollWidth = containerRef.current.offsetWidth;
        scrollAmount.current += scrollWidth * 0.2; // Use scrollAmount.current
        containerRef.current.scrollTo({
          left: scrollAmount.current,
          behavior: "smooth",
        });

        if (scrollAmount.current >= containerRef.current.scrollWidth) {
          scrollAmount.current = 0;
          containerRef.current.scrollTo({ left: 0 });
        }
      }
    }, 5000);

    return () => clearInterval(scrollInterval);
  }, [isPaused]);

  const handlePrevious = () => {
    if (containerRef.current) {
      const scrollWidth = containerRef.current.offsetWidth;
      scrollAmount.current -= scrollWidth * 0.8;
      if (scrollAmount.current < 0) {
        scrollAmount.current = containerRef.current.scrollWidth;
      }
      containerRef.current.scrollTo({
        left: scrollAmount.current,
        behavior: "smooth",
      });
    }
  };

  const handleNext = () => {
    if (containerRef.current) {
      const scrollWidth = containerRef.current.offsetWidth;
      scrollAmount.current += scrollWidth * 0.8;
      if (scrollAmount.current >= containerRef.current.scrollWidth) {
        scrollAmount.current = 0;
      }
      containerRef.current.scrollTo({
        left: scrollAmount.current,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <h1 className="mn-2 text-lg font-semibold">Programme We Offer</h1>
      <p className="mb-4">
        At OIT, we offer a wide range of IT courses designed to help you build
        foundational skills or advance your existing knowledge. Our offerings
        include:
      </p>
      <div
        className="programme-container"
        style={{
          position: "relative",
          overflow: "hidden",
          padding: "50px",
        }}>
        {/* Previous Button */}
        <button
          onClick={handlePrevious}
          style={{
            position: "absolute",
            top: "50%",
            left: "10px",
            transform: "translateY(-50%)",
            zIndex: 1,
            // backgroundColor: "#000",
            color: "#fff",
            padding: "10px",
            cursor: "pointer",
          }}
          className="rounded-full dark-background">
          <IoChevronBack />
        </button>

        <div
          ref={containerRef}
          className="flex flex-row gap-10"
          style={{
            display: "flex",
            overflowX: "auto",
            scrollbarWidth: "none",
            scrollBehavior: "smooth",
          }}>
          {programmes.map((program, index) => (
            <div key={index}>
              <ProgrammeCard
                title={program.title}
                image={program.image}
                info={program.info}
                redirection={program.programInfo}
              />
            </div>
          ))}
        </div>

        {/* Next Button */}
        <button
          onClick={handleNext}
          style={{
            position: "absolute",
            top: "50%",
            right: "10px",
            transform: "translateY(-50%)",
            zIndex: 1,
            // backgroundColor: "#000",
            color: "#fff",
            padding: "10px",
            cursor: "pointer",
          }}
          className="rounded-full dark-background">
          <IoChevronForward />
        </button>

        {/* Pause/Resume Button */}
        <button
          onClick={() => setIsPaused(!isPaused)}
          style={{
            position: "absolute",
            bottom: "10px",
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "#000",
            color: "#fff",
            padding: "10px",
            cursor: "pointer",
          }}
          className="rounded-full hidden">
          {isPaused ? <FaPlay /> : <FaPause />}
        </button>
      </div>
    </>
  );
}

export default ProgrammeCarousel;
