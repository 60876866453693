import { ProgramQandA } from "../../cards";
import React, { useState } from "react";

function CloudComputingQandA() {
  const [openIndex, setOpenIndex] = useState(0); // First question open by default
  const courseDetails = [
    {
      title: "Course Outline",
      details: [
        "Module 1: Introduction to Cloud Computing",
        "Module 2: Cloud Service Providers",
        "Module 3: Cloud Architecture & Key Components",
        "Module 4: Networking in the Cloud",
        "Module 5: Cloud Management and Monitoring",
        "Module 6: Cloud Migration & Future Trends",
        "Linux Fundamental (Included)",
      ],
      isCourseOutline: true,
    },
    {
      title: "Entry Requirements",
      details: ["• Prefer Basic Computer & IT Knowledge"],
      isCourseOutline: false,
    },
    {
      title: "What you'll learn",
      details: [
        "• Gain a solid grasp of cloud computing basics, including what the cloud is, its architecture, and its key components like compute, storage, and networking.",
        "• Learn the three main cloud service models: Infrastructure as a Service (IaaS), Platform as a Service (PaaS), and Software as a Service (SaaS), and when to use each.",
        "• Understand the differences between public, private, and hybrid cloud environments, and how to select the appropriate deployment model for different business needs.",
        "• Learn how to configure and deploy basic cloud infrastructure, including virtual machines, storage, and networks, on platforms like AWS, Azure, or Google Cloud.",
        "• Develop the skills to build, deploy, and manage cloud-based applications, preparing you for further study or real-world cloud computing roles.",
      ],
      isCourseOutline: false,
    },
    // {
    //   title: "Teach Digi",
    //   details: [
    //     "Lorem ipsum dolor sit amet consectetur. Scelerisque facilisi orci cras dignissim tristique et nunc eget pharetra.",
    //     "Tempus laoreet at eget sed. Egestas sollicitudin quam justo velit faucibus mi orci pellentesque neque.",
    //     "Turpis mauris mauris integer consequat id tincidunt. Vitae tortor viverra sapien et euismod.",
    //     "Est tortor et cras quam id gravida nibh sed ultrices. Pharetra ac at metus sem lectus senectus.",
    //     "Ultricies lectus adipiscing at porttitor augue vehicula faucibus venenatis.",
    //     "Est sed viverra volutpat tellus facilisi risus. Phasellus amet neque cursus leo.",
    //     "Tempor dui a at porta sed nisi nec.",
    //     "Pulvinar nunc tincidunt nulla id adipiscing.",
    //   ],
    //   isCourseOutline: false,
    // },
    // {
    //   title: "FAQ",
    //   details: [
    //     "Lorem ipsum dolor sit amet consectetur. Scelerisque facilisi orci cras dignissim tristique et nunc eget pharetra.",
    //     "Tempus laoreet at eget sed. Egestas sollicitudin quam justo velit faucibus mi orci pellentesque neque.",
    //     "Turpis mauris mauris integer consequat id tincidunt. Vitae tortor viverra sapien et euismod.",
    //     "Est tortor et cras quam id gravida nibh sed ultrices. Pharetra ac at metus sem lectus senectus.",
    //     "Ultricies lectus adipiscing at porttitor augue vehicula faucibus venenatis.",
    //     "Est sed viverra volutpat tellus facilisi risus. Phasellus amet neque cursus leo.",
    //     "Tempor dui a at porta sed nisi nec.",
    //     "Pulvinar nunc tincidunt nulla id adipiscing.",
    //   ],
    //   isCourseOutline: false,
    // },
  ];
  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index); // Toggle the index
  };

  return (
    <>
      {courseDetails.map((detail, index) => (
        <ProgramQandA
          key={index}
          question={detail.title}
          answers={detail.details}
          isCourseOutline={detail.isCourseOutline}
          isOpen={openIndex === index} // Pass whether this question is open
          onToggle={() => handleToggle(index)} // Pass the toggle function
        />
      ))}
    </>
  );
}

export default CloudComputingQandA;
