function TeachingApproachCard({ icon, title, info }) {
  return (
    <>
      <div
        className="flex flex-col justify-start  text-justify p-5"
        style={{
          backgroundColor: "var(--Backgrounds-Primary, #FFF)",
          boxShadow: "0px 4px 4px 4px #00A6FE",
          width: "300px",
          height: "270px",
        }}>
        <div
          className="bg-white p-3 rounded-full -translate-y-12 w-fit ml-20"
          style={{
            boxShadow: "0px 4px 4px 0px rgba(0, 166, 254, 0.25)",
          }}>
          <img
            src={icon}
            alt={title}
            style={{
              width: "40px",
              height: "40px",
              justifySelf: "center",
              position: "relative",
            }}
          />
        </div>
        <h1
          className="text-lg font-bold mb-2 -translate-y-10"
          style={{ color: "#162B3E" }}>
          {title}
        </h1>
        <p
          className="text-lg text-start font-normal -translate-y-8"
          style={{ color: "#162B3E" }}>
          {info}
        </p>
      </div>
    </>
  );
}

export default TeachingApproachCard;
