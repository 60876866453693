import { motion } from "framer-motion";
import TeachingApproachCard from "../cards/TeachingApproachCard";
import {
  realworldProjects,
  smallClassSizes,
  mentorship,
  peerLearning,
} from "../../assets";

function TeachingApproach() {
  const methods = [
    {
      icon: realworldProjects,
      title: "Real-World Projects",
      info: "Each course includes hands-on projects where students solve real-world problems, giving them a portfolio to showcase to future employers.",
    },
    {
      icon: smallClassSizes,
      title: "Small Class Sizes",
      info: "Our small class sizes ensure personalized attention, allowing instructors to focus on each student’s growth.",
    },
    {
      icon: mentorship,
      title: "Mentorship",
      info: "Gain insights from experienced instructors and mentors who guide you through every step of your learning journey.",
    },
    {
      icon: peerLearning,
      title: "Peer Learning",
      info: "We foster a collaborative learning environment where students can learn from each other’s experiences and perspectives.",
    },
  ];

  return (
    <motion.div
      className="flex flex-col min-h-[620px] justify-center items-center pb-10 p-7 text-white"
      style={{ background: "var(--Dark-Black, #162B3E)" }}
      // Increase the height of the container to prevent overflow
    >
      <h1 className="text-3xl font-bold mb-4">Our Teaching Approach</h1>
      <p className="mb-5">
        At OIT, we believe that practical learning is key to mastering IT
        skills. That’s why all our courses focus on:
      </p>
      <motion.div className="flex flex-row h-auto p-5 pt-20 gap-10">
        {methods.map((method, index) => (
          <motion.div
            className="mb-5"
            key={index}
            animate={{
              y: index % 2 === 0 ? 80 : -60,
            }}
            transition={{
              duration: 2,
              repeat: Infinity,
              repeatType: "reverse",
              delay: 2,
              ease: "easeInOut",
            }}>
            <TeachingApproachCard
              title={method.title}
              info={method.info}
              icon={method.icon}
            />
          </motion.div>
        ))}
      </motion.div>
    </motion.div>
  );
}

export default TeachingApproach;
