import { createContext, useContext, useState } from "react";

// Create a context
const QandAContext = createContext();

export function useQandA() {
  return useContext(QandAContext);
}

export function QandAProvider({ children }) {
  const [openIndex, setOpenIndex] = useState(null); // State to track the open question

  // Context value to share openIndex and setOpenIndex
  const value = {
    openIndex,
    setOpenIndex,
  };

  return (
    <QandAContext.Provider value={value}>{children}</QandAContext.Provider>
  );
}
