import React from "react";
import { FaCircleChevronDown, FaCircleChevronUp } from "react-icons/fa6";

function FaQCard({ question, answers, isOpen, onToggle }) {
  return (
    <div
      className={`border-2 flex justify-center flex-col cursor-pointer w-10/12 rounded-md`}
      style={{ padding: "19px 31px", margin: "10px 100px" }}
      onClick={onToggle}>
      <div className="flex flex-row justify-between">
        <h1 className="text-base font-medium">{question}</h1>
        <p>
          {isOpen ? (
            <FaCircleChevronUp size={25} />
          ) : (
            <FaCircleChevronDown size={25} />
          )}
        </p>
      </div>
      {isOpen && <p className="text-left w-11/12 mt-3">{answers}</p>}
    </div>
  );
}

export default FaQCard;
