import { Helmet } from "react-helmet";
import { NewsArticles, NewsHeader } from "../components";

function NewsAndArticles() {
  return (
    <>
      <Helmet>
        <title>News and Articles | Origin Institude of Technology</title>
      </Helmet>
      <NewsHeader />
      <NewsArticles />
    </>
  );
}

export default NewsAndArticles;
