import { siteLogo } from "../../assets";
import "./index.css";

function Pdev() {
  return (
    <>
      <div className="flex flex-col justify-center text-center items-center">
        <div className="flex flex-row justify-center w-full items-center">
          <div>
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="97"
              height="6"
              viewBox="0 0 97 6"
              fill="none">
              <path
                d="M90.8333 3C90.8333 4.47276 92.0272 5.66667 93.5 5.66667C94.9728 5.66667 96.1667 4.47276 96.1667 3C96.1667 1.52724 94.9728 0.333333 93.5 0.333333C92.0272 0.333333 90.8333 1.52724 90.8333 3ZM0.5 3.5H93.5V2.5H0.5V3.5Z"
                fill="#162B3E"
              />
            </svg>
          </div>
          <div>
            <img
              src={siteLogo}
              alt="Site Logo"
              style={{ width: "144px", height: "140px" }}
            />
          </div>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="97"
              height="6"
              viewBox="0 0 97 6"
              fill="none">
              <path
                d="M0.833333 3C0.833333 4.47276 2.02724 5.66667 3.5 5.66667C4.97276 5.66667 6.16667 4.47276 6.16667 3C6.16667 1.52724 4.97276 0.333333 3.5 0.333333C2.02724 0.333333 0.833333 1.52724 0.833333 3ZM3.5 3.5L96.5 3.49999L96.5 2.49999L3.5 2.5L3.5 3.5Z"
                fill="#162B3E"
              />
            </svg>
          </div>
        </div>
        <div>
          <h1 className="dark-text mb-3 p-dev-heading">
            Professional Development Programme
          </h1>
          <p className="p-dev-text mt-2 mb-10" style={{ lineHeight: "25px" }}>
            Elevate your career with our Professional Development programs. Gain
            essential skills, enhance your expertise, and connect with industry
            leaders. Whether you’re seeking certification or advanced training,
            our tailored courses empower you to thrive in a competitive
            landscape. Join us today and take the next step in your professional
            journey!
          </p>
        </div>
      </div>
    </>
  );
}

export default Pdev;
