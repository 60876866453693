import { aboutHeadingLogo, siteLogo } from "../../assets";

function ProgrammeWeOffer() {
  return (
    <>
      <div
        className="flex flex-col text-white items-center pb-7"
        style={{ background: "var(--Dark-Black, #162B3E)" }}>
        <div>
          <img
            src={aboutHeadingLogo}
            alt="Logo"
            style={{ height: "148px", width: "267px" }}
          />
        </div>
        <div
          className="flex flex-row gap-24"
          style={{
            border: "1px solid white",
            padding: "25px 130px",
          }}>
          <div className="flex flex-col justify-center items-center max-h-40">
            <iframe
              src="https://lottie.host/embed/21ffb26e-3cf9-4f24-b388-3e8402356f76/cjWikV8CNB.json"
              title="book"
              className="w-36"></iframe>
            <h5 className="text-lg mb-4 mb-4xx">Programme</h5>
            <p style={{ fontSize: "16px" }}>8</p>
          </div>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="6"
              height="155"
              viewBox="0 0 6 155"
              fill="none">
              <path
                d="M3 0.833333C1.52724 0.833333 0.333333 2.02724 0.333333 3.5C0.333333 4.97276 1.52724 6.16667 3 6.16667C4.47276 6.16667 5.66667 4.97276 5.66667 3.5C5.66667 2.02724 4.47276 0.833333 3 0.833333ZM2.99999 148.833C1.52723 148.833 0.333327 150.027 0.333327 151.5C0.333327 152.973 1.52723 154.167 2.99999 154.167C4.47275 154.167 5.66666 152.973 5.66666 151.5C5.66666 150.027 4.47275 148.833 2.99999 148.833ZM2.5 3.5L2.49999 151.5L3.49999 151.5L3.5 3.5L2.5 3.5Z"
                fill="white"
              />
            </svg>
          </div>
          <div className="flex flex-col justify-center -translate-y-8">
            <iframe
              src="https://lottie.host/embed/3da10601-b8b3-4045-9ad5-35af2679e546/gX5NFfRDtM.json"
              title="student with laptop"
              className="w-16"></iframe>
            <h5 className="text-lg mb-4">Students</h5>
            <p style={{ fontSize: "16px" }}>100+</p>
          </div>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="6"
              height="155"
              viewBox="0 0 6 155"
              fill="none">
              <path
                d="M3 0.833333C1.52724 0.833333 0.333333 2.02724 0.333333 3.5C0.333333 4.97276 1.52724 6.16667 3 6.16667C4.47276 6.16667 5.66667 4.97276 5.66667 3.5C5.66667 2.02724 4.47276 0.833333 3 0.833333ZM2.99999 148.833C1.52723 148.833 0.333327 150.027 0.333327 151.5C0.333327 152.973 1.52723 154.167 2.99999 154.167C4.47275 154.167 5.66666 152.973 5.66666 151.5C5.66666 150.027 4.47275 148.833 2.99999 148.833ZM2.5 3.5L2.49999 151.5L3.49999 151.5L3.5 3.5L2.5 3.5Z"
                fill="white"
              />
            </svg>
          </div>

          <div className="flex flex-col justify-center max-h-40">
            <iframe
              src="https://lottie.host/embed/1a49e49f-643a-455a-8731-c16aa696ee3d/tBat43VJis.json"
              title="Teacher"
              className="w-14"></iframe>
            <h5 className="text-lg mb-4">Lecture</h5>{" "}
            <p style={{ fontSize: "16px" }}>20</p>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full justify-center">
        <div className="flex flex-row  items-center justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="96"
            height="6"
            viewBox="0 0 96 6"
            fill="none">
            <path
              d="M90.3333 3C90.3333 4.47276 91.5272 5.66667 93 5.66667C94.4728 5.66667 95.6667 4.47276 95.6667 3C95.6667 1.52724 94.4728 0.333333 93 0.333333C91.5272 0.333333 90.3333 1.52724 90.3333 3ZM0 3.5H93V2.5H0V3.5Z"
              fill="#162B3E"
            />
          </svg>
          <img
            src={siteLogo}
            style={{ width: "100px", height: "100px" }}
            alt="Logo"
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="96"
            height="6"
            viewBox="0 0 96 6"
            fill="none">
            <path
              d="M0.333333 3C0.333333 4.47276 1.52724 5.66667 3 5.66667C4.47276 5.66667 5.66667 4.47276 5.66667 3C5.66667 1.52724 4.47276 0.333333 3 0.333333C1.52724 0.333333 0.333333 1.52724 0.333333 3ZM3 3.5L96 3.49999L96 2.49999L3 2.5L3 3.5Z"
              fill="#162B3E"
            />
          </svg>
        </div>
      </div>
    </>
  );
}

export default ProgrammeWeOffer;
