import { Link } from "react-router-dom";
import { grayLogo } from "../../assets";

function ProgramIntroCard({ title, details, programImg, downloadLink }) {
  return (
    <>
      <div
        className=" dark-background text-white text-left"
        style={{ padding: "40px 120px" }}>
        {" "}
        <div>
          <img
            src={grayLogo}
            alt="Institude Logo"
            style={{ width: "120px", height: "120px" }}
          />
        </div>
        <div className="flex flex-row gap-5 justify-evenly">
          <div className="flex flex-col">
            <div>
              <h3>{title}</h3>
              <div className="mt-5 mb-5 flex flex-row">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="73"
                  height="6"
                  viewBox="0 0 73 6"
                  fill="none">
                  <path
                    d="M0.333333 3C0.333333 4.47276 1.52724 5.66667 3 5.66667C4.47276 5.66667 5.66667 4.47276 5.66667 3C5.66667 1.52724 4.47276 0.333333 3 0.333333C1.52724 0.333333 0.333333 1.52724 0.333333 3ZM3 3.5L73 3.49999L73 2.49999L3 2.5L3 3.5Z"
                    fill="white"
                  />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="96"
                  height="6"
                  viewBox="0 0 96 6"
                  fill="none">
                  <path
                    d="M90.3333 3C90.3333 4.47276 91.5272 5.66667 93 5.66667C94.4728 5.66667 95.6667 4.47276 95.6667 3C95.6667 1.52724 94.4728 0.333333 93 0.333333C91.5272 0.333333 90.3333 1.52724 90.3333 3ZM0 3.5H93V2.5H0V3.5Z"
                    fill="white"
                  />
                </svg>
              </div>
              <p className="max-w-[632px] text-justify">{details}</p>
            </div>
            <Link to={downloadLink}>
              {" "}
              <button className="flex flex-row items-center mt-10 bg-white p-2 rounded-md border-4 border-blue-300">
                <iframe
                  src="https://lottie.host/embed/359e5c72-cb08-4b3a-8640-66f610bec12d/zsw9ygRpAU.json"
                  title="download button"
                  style={{ height: "25px", width: "25px" }}></iframe>
                <p className="text-sm ml-1 bg-white dark-text">
                  Download Brochure
                </p>
              </button>
            </Link>
          </div>
          <div>
            <img
              src={programImg}
              alt={title}
              style={{ width: "500px", height: "auto" }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ProgramIntroCard;
