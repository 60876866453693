import { DiplomaHeading, DiplomaMidSec, DiplomaPrograms } from "../components";
import { Helmet } from "react-helmet";

function Diploma() {
  return (
    <>
      <Helmet>
        <title>Diploma | Origin Institude of Technology</title>
      </Helmet>
      <DiplomaHeading />
      <DiplomaMidSec />
      <DiplomaPrograms />
    </>
  );
}

export default Diploma;
