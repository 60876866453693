import "./App.css";
import "./index.css";
import Heading from "./layout/Heading";
import Footer from "./layout/Footer";
import { RouterConfig } from "./components";
import { QandAProvider } from "./stateManagement/AppProvider";
function App() {
  return (
    <QandAProvider>
      <div className="flex flex-col App">
        <Heading />
        <div style={{ paddingTop: "160px" }}>
          <RouterConfig />
        </div>
        <Footer />
      </div>
    </QandAProvider>
  );
}

export default App;
