import { Helmet } from "react-helmet";
import {
  HeroHeader,
  // OurPartnership,
  ProgrammeWeOffer,
  TeachingApproach,
  WhyChooseOIT,
  ProgrammeCarousel,
} from "../components";

function Hero() {
  return (
    <>
      <Helmet>
        <title>Home | Origin Institude of Technology</title>
      </Helmet>
      <HeroHeader />
      <TeachingApproach />
      <WhyChooseOIT />
      <ProgrammeWeOffer />
      <ProgrammeCarousel />
      {/* <OurPartnership /> */}
    </>
  );
}

export default Hero;
