import { ccHeaderBg } from "../../../assets";
import HeadingCard from "../../cards/HeadingCard";

function SoftwareDevelopmentHeading() {
  return (
    <>
      <HeadingCard title="Software Development" backgroundImage={ccHeaderBg} />
    </>
  );
}

export default SoftwareDevelopmentHeading;
