import React, { useState, useEffect } from "react";
import "./index.css";
import { Link, NavLink } from "react-router-dom";
// import { FiMenu } from "react-icons/fi";
import { siteLogo } from "../assets";
import { FaChevronDown } from "react-icons/fa";
const Heading = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 767);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 767);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const navItems = [
    { label: "E-Library", to: "/coming-soon", exact: true },
    { label: "Alumini", to: "/coming-soon", exact: true },
    { label: "Forum", to: "/coming-soon", exact: true },
    { label: "FAQs", to: "/faqs", exact: true },
  ];

  return (
    <>
      {isDesktop && (
        <>
          <nav className="flex max-h-36 flex-col justify-start bg-white fixed text-lg w-full z-30 ">
            <div className="flex flex-row items-center justify-between">
              <NavLink
                to="/"
                exact
                className="flex flex-row items-center justify-start">
                <div className="mt-2">
                  <img
                    src={siteLogo}
                    alt="Logo Of Rapid"
                    className="h-32 w-auto ml-16"
                  />
                </div>
                <div>
                  <h1 className="text-2xl pl-5 heading-h1">
                    ORIGIN INSTITUTE OF TECHNOLOGY
                  </h1>
                </div>
              </NavLink>
              <div className="flex flex-row mr-0 ml-auto align-middle items-center pt-8 pb-8 gap-6 w-max-1/2">
                {navItems.map((item, index) => (
                  <NavLink
                    key={index}
                    to={item.to}
                    exact={item.exact}
                    className="hover-cyan"
                    onClick={toggleMobileMenu}>
                    {item.label}
                  </NavLink>
                ))}
                <div className="mr-12">
                  <button className="border-2 pt-2 pb-2 pl-10 pr-10 rounded-full header-button login-button login-hover hover:bg-gradient-to-r hover:from-sky-500 hover:to-blue-950 hover:text-white">
                    Login
                  </button>
                </div>
              </div>
            </div>
            <div>
              <div>
                <div
                  className="flex flex-row justify-between text-white"
                  style={{
                    backgroundColor: "#162B3E",
                  }}>
                  <div className="flex  pr-12 flex-row gap-7 justify-between items-center">
                    <div className="page-option hover-cyan ml-60">
                      <Link to="/"> Home</Link>
                    </div>
                    <div className="page-option hover-cyan">
                      <Link to="/about">About Us</Link>
                    </div>
                    <div className="page-option-with-dropdown">
                      <div className="flex gap-1 flex-row items-center rounded-none">
                        Programme <FaChevronDown className="w-3 ml-2" />
                      </div>
                      <div>
                        <div className="flex flex-col z-10 absolute rounded-lg header-button">
                          <Link to="/diploma">
                            {" "}
                            <p className="dropdown-item rounded-t-lg">
                              Diploma
                            </p>
                          </Link>
                          <Link to="./professional-development">
                            <p className="dropdown-item">
                              Professional Development
                            </p>
                          </Link>
                          <p className="dropdown-item rounded-b-lg">Others</p>
                        </div>
                      </div>
                    </div>
                    <div className="page-option-with-dropdown rounded-b-md">
                      <div className="flex gap-1 flex-row items-center rounded-none">
                        Partnership <FaChevronDown className="w-3 ml-2" />
                      </div>
                      <div className="flex flex-col border-2 z-10 absolute header-button">
                        <Link to="/coming-soon">
                          <p className="dropdown-item rounded-t-lg">
                            Academic Partnership
                          </p>
                        </Link>
                        <Link to="/coming-soon">
                          <p className="dropdown-item rounded-b-lg">
                            Cooroperate Partnership
                          </p>
                        </Link>
                      </div>
                    </div>
                    <Link to="/news-and-articles">
                      <div className="page-option hover-cyan">
                        News & Activities
                      </div>
                    </Link>{" "}
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </>
      )}

      {/* {!isDesktop && (
          <>
            <div>
              <div>
                <div
                  onClick={toggleMobileMenu}
                  style={{ marginRight: "0px", marginLeft: "auto" }}>
                  <FiMenu
                    size={30}
                    style={{
                      color: "black",
                      border: "3px solid",
                      borderRadius: "20%",
                    }}
                  />
                </div>
                {isMobileMenuOpen && (
                  <>
                    <div>
                      {navItems.map((item, index) => (
                        <div>
                          <NavLink
                            key={index}
                            to={item.to}
                            exact={item.exact}
                            className="nav-bar-list"
                            onClick={toggleMobileMenu}>
                            {item.label}
                          </NavLink>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )} */}
    </>
  );
};

export default Heading;
