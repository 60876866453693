import { GoDotFill } from "react-icons/go";
import "./index.css";
import { organizationStructure } from "../../assets";

export default function VisionAndMission() {
  return (
    <>
      <div className="flex flex-col gap-8 pt-8 pb-8 items-center justify-center">
        <div className="flex flex-row justify-center gap-0">
          <div className="mission max-h-56">
            <h3
              style={{
                position: "relative",
                textAlign: "center",
                left: "40%",
                marginBottom: "20px",
              }}>
              Our Vision
            </h3>
            <p>
              To be a leading institution that drives technological innovation
              and transforms learners into future-ready professionals who make a
              significant impact in the Local and Global technology landscape.
            </p>
          </div>
          <div className="mission-img-ctn max-h-56">
            <iframe
              src="https://lottie.host/embed/c9368271-7c36-475a-a8f7-efdb14538531/ZdevAjPcYd.json"
              title="Vision"
              className="-translate-x-24"></iframe>
          </div>
        </div>
        <div className="flex flex-row-reverse justify-center  gap-0">
          <div className="mission ">
            <h3
              style={{
                position: "relative",
                textAlign: "center",
                left: "45%",
                marginBottom: "20px",
              }}>
              Our Mission
            </h3>
            <p className="text-left text-sm">
              Our mission at Origin Institute of Technology (OIT) is to deliver
              industry-aligned, practical education that prepares students to
              thrive in the competitive and ever-changing IT sector. We focus
              on:
              <ul className="text-white text-left mt-5 ml-20">
                <li className="flex flex-row justify-start gap-3">
                  {" "}
                  <GoDotFill size={10} />
                  Providing comprehensive, hands-on training that turns learners
                  into job-ready professionals.
                </li>
                <li className="flex flex-row justify-start gap-3">
                  {" "}
                  <GoDotFill size={10} />
                  Fostering innovation and creativity through a curriculum that
                  emphasizes critical thinking, problem-solving, and real-world
                  application.
                </li>
                <li className="flex flex-row justify-start gap-3">
                  {" "}
                  <GoDotFill size={10} />
                  Building a community of lifelong learners who are adaptive,
                  curious, and driven to excel in technology.
                </li>
              </ul>{" "}
            </p>
          </div>
          <div className="mission-img-ctn h-full mt-14">
            <iframe
              src="https://lottie.host/embed/c9666e24-a71d-47d9-975b-fa11c2af1d26/wh2AVqZk5r.json"
              title="vision"
              className="-translate-x-24"></iframe>
          </div>
        </div>
      </div>
      <div
        className="min-w-full min-h-full"
        style={{
          backgroundImage: `url(${organizationStructure})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}>
        <img
          src={organizationStructure}
          alt="org Structure"
          style={{ width: "100vw" }}
        />
      </div>
    </>
  );
}
