import { pDevHeaderBg } from "../../../assets";
import HeadingCard from "../../cards/HeadingCard";

function ItpecHeading() {
  return (
    <>
      <HeadingCard
        backgroundImage={pDevHeaderBg}
        title="Information Technology Professionals  Examination Council (ITPEC)"
      />
    </>
  );
}

export default ItpecHeading;
