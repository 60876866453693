function WhyChooseOIT() {
  return (
    <>
      <div className="flex flex-row justify-center items-center gap-16 p-5">
        <div>
          <iframe
            src="https://lottie.host/embed/8058010b-3088-4642-98bc-f00b47d05105/IovErBLc9V.json"
            title="Happy Family"
            style={{ width: "500px", height: "375px" }}
          />
        </div>
        <div className="w-1/2 justify-center items-center text-justify">
          <h1 className="mb-8 text-3xl font-bold">Why Choose OIT?</h1>
          <p className="mb-2 w-max-[706px]" style={{ fontSize: "16px" }}>
            <b> Practical, Hands-On Learning:</b> We believe that the best way
            to learn technology is by doing. That’s why all of our courses
            incorporate practical, project-based learning to help you build
            real-world skills.
          </p>
          <p className="mb-2 w-max-[706px]" style={{ fontSize: "16px" }}>
            <b> Expert Faculty:</b> Our instructors are not only experienced in
            their fields but also passionate about teaching. They bring
            real-world industry insights and guide you through every step of
            your learning journey.
          </p>
          <p className="mb-2 w-max-[706px] " style={{ fontSize: "16px" }}>
            <b>Cutting-Edge Curriculum:</b> OIT’s programs are regularly updated
            to keep up with the latest industry trends and technologies,
            ensuring that you learn skills that are relevant and in demand.
          </p>
          <p className="mb-2  w-max-[706px]" style={{ fontSize: "16px" }}>
            <b>Career Focused:</b> From day one, our courses are designed to get
            you career-ready. We offer personalized career support services,
            including job placement assistance, resume building, and interview
            coaching.
          </p>
          <p className="mb-2 w-max-[706px]" style={{ fontSize: "16px" }}>
            <b>Industry Partnerships:</b> We collaborate with leading companies
            to offer internship programs, workshops, and networking
            opportunities, giving you a competitive edge in the job market
          </p>
        </div>
      </div>
    </>
  );
}

export default WhyChooseOIT;
