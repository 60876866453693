import { useState } from "react";
import { ProgramQandA } from "../../cards";

function ItpecQandA() {
  const [openIndex, setOpenIndex] = useState(0); // First question open by default

  const courseDetails = [
    {
      title: "Course Outline",
      details: [
        "<b>IP :</b>",
        "1 Corporate and legal affairs (Strategy)",
        "2 Business strategy (strategy)",
        "3 System strategy (Strategy)",
        "4 Development technology (Management)",
        "5 Project management (Management)",
        "6 Service management (Management)",
        "7 Basic theory (Technology)",
        "8 Computer system (Technology)",
        "9 Technology element (Technology)",
        "10 Spreadsheet",
        "<b>Extra Courses<b/>",
        "(i)   Microsoft Word",
        "(ii)  Microsoft Excel",
        "(iii) Microsoft PowerPoint",
        "<b>FE: Vol-1 (IT) </b>",
        "Chapter 1  : Hardware (Technology)",
        "Chapter 2 : Information Processing System (Technology)",
        "Chapter 3 : Software (Technology)",
        "Chapter 4 : Database (Technology) ",
        "Chapter 5 : Network (Technology)",
        "Section 6  : Security (Technology)",
        "Chapter 7 : Data Structure and Algorithm (Technology)",
        " <b>FE: Vol-2 (Strategy and Management) </b>",
        "Chapter 1  :   Corporate and Legal Affairs (Strategy)",
        "Chapter 2 : Business Strategy (Strategy)",
        "Chapter 3 : Information Systems Strategy (Strategy)",
        "Chapter 4 : Development Technology (Management)",
        "Chapter 5 : Project Management (Management)",
        "Chapter 6 : Service Management (Management)",
        "Chapter 7 : System Audit and Internal Control (Management)",
      ],
      isCourseOutline: true,
    },
    {
      title: "Entry Requirements",
      details: [
        "• No prior IT Knowledge and skills.",
        "• Prefer Grade 10 and above.",
      ],
      isCourseOutline: false,
    },
    {
      title: "What you'll learn",
      details: [
        "• Understand the fundamental IT concepts required for the ITPEC IP & FE exam.",
        "• Be able to apply knowledge of business and IT strategy to real-world scenarios.",
        "• Have the necessary project management, service management, and system audit skills.",
        "• Be familiar with the basic technologies of computers, networks, databases, and security.",
        "• Gain confidence in taking and passing the ITPEC IP & FE exam.",
      ],
      isCourseOutline: false,
    },
  ];

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index); // Toggle the index
  };

  return (
    <>
      {courseDetails.map((detail, index) => (
        <ProgramQandA
          key={index}
          question={detail.title}
          answers={detail.details}
          isCourseOutline={detail.isCourseOutline}
          isOpen={openIndex === index} // Pass whether this question is open
          onToggle={() => handleToggle(index)} // Pass the toggle function
        />
      ))}
    </>
  );
}

export default ItpecQandA;
