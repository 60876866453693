import { RxCalendar } from "react-icons/rx";
import { grayLogo } from "../../assets";
import { Link } from "react-router-dom";
import "./index.css";

function NewsCard({ title, details, redirect, backgroundImage, currentIndex }) {
  return (
    <>
      {" "}
      <Link to={`news/${redirect}`} style={{ lineHeight: "20px" }}>
        <div
          className="flex flex-col w-[370px]  h-[386px] news-card-container text-left border-4 dark-text light-blue-shadow "
          style={{ borderRadius: "24px" }}>
          <div
            style={{
              background: `url(${backgroundImage})`,
              backgroundSize: "cover",
              backgroundPosition: "top",
              backgroundRepeat: "no-repeat",
              height: "370",  
            }}>
            <div className="flex flex-row article-faded-bg justify-between pt-10 pb-10">
              <div>
                <img
                  src={grayLogo}
                  style={{ height: "80px", width: "80px", opacity: "0.01" }}
                  alt={title}
                />{" "}
              </div>
              <button className="light-blue-shadow hover:bg-gradient-to-r hover:from-sky-500 hover:to-blue-950 hover:text-white h-8 border-2 bg-white text-black p-1 translate-y-24 pl-3 pr-3 rounded-3xl mr-3">
                {`#Article${currentIndex + 1}`}
              </button>
            </div>{" "}
          </div>
          <div className="flex flex-col justify-between pb-6 pl-4 pt-2 pr-1 h-56">
            <div>
              <h1 className="text-lg font-bold pb-3 pt-2">{title}</h1>
              <p>{details}</p>
            </div>
            <div className="flex flex-row justify-between items-center">
              <p className="flex flex-row justify-start items-center">
                <RxCalendar className="w-4 h-4 mr-2" /> <span>26.9.2024</span>
              </p>
              <p className="mr-2">Read More {">>"}</p>
            </div>
          </div>
        </div>
      </Link>{" "}
    </>
  );
}

export default NewsCard;
