import { Helmet } from "react-helmet";
import {
  CloudComputingHeading,
  CloudComputingIntro,
  CloudComputingQandA,
} from "../components";

function CloudComputing() {
  return (
    <>
      <Helmet>
        <title>Cloud Computing | Origin Institude of Technology</title>
      </Helmet>
      <CloudComputingHeading />
      <CloudComputingIntro />
      <CloudComputingQandA />
    </>
  );
}

export default CloudComputing;
