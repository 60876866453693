import "./index.css";

function HeadingCard({ backgroundImage, title }) {
  return (
    <div
      className="w-full flex justify-center"
      style={{
        height: "294px",
        backgroundColor: "var(--Dark-Black, #162B3E)",
        justifyContent: "center",
        paddingTop: "58px",
      }}>
      <div
        className="pdev-container"
        style={{
          height: "237px",
          padding: "0px 250px",
          width: "870px",
          position: "relative",
          overflow: "visible",
        }}>
        <div
          className="flex items-center justify-center"
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "auto 237px ",
            margin: "0px 135px",
            backgroundRepeat: "no-repeat",
            backgroundPositionX: "center",
            height: "237px",
            position: "absolute", // Use absolute positioning
            top: "50%", // Center vertically
            left: "50%", // Center horizontally
            transform: "translate(-70%, -50%)", // Adjust for centering
          }}>
          <h1
            className="text-5xl text-white whitespace-nowrap overflow-visible "
            style={{
              letterSpacing: "5.44px",
              WebkitTextStrokeWidth: "1px black",
              WebkitTextStroke: "0.2px black",
            }}
            dangerouslySetInnerHTML={{
              // eslint-disable-next-line
              __html: title.replace(/  /g, "&nbsp;<br/>&nbsp;"),
            }}></h1>
        </div>
      </div>
    </div>
  );
}

export default HeadingCard;
