import { Helmet } from "react-helmet";
import {
  SoftwareDevelopmentHeading,
  SoftwareDevelopmentIntro,
  SoftwareDevelopmentQandA,
} from "../components";

function SoftwareDev() {
  return (
    <>
      <Helmet>
        <title>Software Development | Origin Institude of Technology</title>
      </Helmet>
      <SoftwareDevelopmentHeading />
      <SoftwareDevelopmentIntro />
      <SoftwareDevelopmentQandA />
    </>
  );
}

export default SoftwareDev;
