import { Helmet } from "react-helmet";
import { AboutHeading, VisionAndMission } from "../components";

function AboutUs() {
  return (
    <>
      <Helmet>
        <title>About Us | Origin Institude of Technology</title>
      </Helmet>
      <AboutHeading />
      <VisionAndMission />
      {/* <Hierarchy /> */}
    </>
  );
}

export default AboutUs;
