import { Helmet } from "react-helmet";
import { Pdev, PdevHeading, Programs } from "../components";

function ProfessionalDevelopment() {
  return (
    <>
      <Helmet>
        <title>Professional Development | Origin Institude of Technology</title>
      </Helmet>
      <Pdev />
      <PdevHeading />
      <Programs />
    </>
  );
}

export default ProfessionalDevelopment;
